/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 
    0px 0px 0px 1px #E0B4B4 inset, 
    0px 2px 4px 0px rgba(34, 36, 38, 0.12), 
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 16px;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 16px;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

.ui.search > .results .result {
  overflow-wrap: break-word;
}

.ui.search > .results .result:hover {
  background-color: lightgrey;
}

.ui.table tr.active,
.ui.table tr:hover {
  background: lightgrey !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.multiple.dropdown {
  width: 150px;
}

.ui.multiple.dropdown>.label{
  font-size: 14px;
}

.ui.label{
  width: 150px;
}

.ui.card {
  width: 290px;
  height: 157px;
  margin: -0.875em -0.5em;
  flex-wrap: wrap;
}
.footer{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.ui.header>.image:not(.icon), .ui.header>img {
  display: inline-block;
  margin-top: 0;
  width: 20px;
  height: auto;
  vertical-align: middle;
}

.styles-article {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.styles-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.styles-header {
  font-size: xx-large;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
} 

.ui.vertical.segment {
  height: 50%;
} 

.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
  text-align: center;
} 

.ui .errMsg {
  font-weight: bold;
}

.ui .errInstancePath {
  font-size: 12px;
}